<template>
  <v-card>
    <v-card-title class="d-flex justify-center">
      <span class="ml-6"> Exportar Comissões </span>

      <v-spacer></v-spacer>

      <v-btn
        class="mr-6"
        color="warning"
        data-test="button-export"
        :disabled="disableDownloadButton"
        outlined
        @click="exportCsv"
      >
        <v-icon
          size="30"
          class="me-2"
        >
          {{ icons.mdiDownload }}
        </v-icon>
        <span> Exportar </span>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div class="container">
        <v-select
          v-model="companyIdSelected"
          :items="companyList"
          item-text="name"
          item-value="id"
          label="Selecione a filial"
          outlined
          dense
        />

        <v-dialog
          ref="dialogDateList"
          v-model="showCalendarInitialDate"
          :return-value.sync="dateList"
          width="400px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateList"
              :append-icon="icons.mdiCalendar"
              :rules="[rules.required]"
              label="Data da inconsistência"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="dateList"
            width="400px"
            locale="pt-BR"
            scrollable
            range
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="showCalendarInitialDate = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialogDateList.save(dateList)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <div class="d-flex justify-end mt-5">
          <v-btn
            class="mr-4"
            outlined
            @click="clearData"
          >
            <v-icon
              size="30"
              class="me-2"
            >
              {{ icons.mdiFilterRemoveOutline }}
            </v-icon>
            Limpar dados
          </v-btn>

          <v-btn
            color="info"
            @click="getDataCloud"
          >
            <span v-if="!loadingCircle">Pesquisar</span>
            <v-progress-circular
              v-else
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiCalendar, mdiDownload, mdiFilterRemoveOutline } from '@mdi/js'
import axios from 'axios'

const ExcelJS = require('exceljs')

export default {
  mixins: [formatters, messages],

  data() {
    return {
      companyList: [
        { id: 3, name: 'Imperatriz' },
        { id: 4, name: 'Açailândia' },
        { id: 5, name: 'Grajaú' },
        { id: 6, name: 'Itinga do Maranhão' },
        { id: 7, name: 'Porto Franco' },
        { id: 8, name: 'Cidelândia' },
        { id: 9, name: 'Amarante do Maranhão' },
        { id: 10, name: 'Arame' },
        { id: 11, name: 'Senador La Roque' },
        { id: 12, name: 'Sitio Novo' },
        { id: 13, name: 'Formosa da Serra Negra' },
        { id: 14, name: 'Bom Jesus das Selvas' },
        { id: 16, name: 'FUJI' },
      ],

      dateList: [],
      employeeList: [],
      commissionList: [],
      companyIdSelected: '',

      loaderExport: false,
      loadingCircle: false,
      disableDownloadButton: true,
      showCalendarFinalDate: false,
      showCalendarInitialDate: false,

      icons: {
        mdiCalendar,
        mdiDownload,
        mdiFilterRemoveOutline,
      },
    }
  },

  computed: {
    computedDateList() {
      const newDate = []
      this.dateList.map(date => {
        newDate.push(this.formatDate(date))
      })

      return newDate
    },
  },

  created() {
    this.getEmployeeList()
  },

  methods: {
    async getEmployeeList() {
      await axiosIns
        .get('api/v1/records/user/all')
        .then(response => {
          this.employeeList = response.data.data
        })
        .catch()
    },

    async getDataCloud() {
      this.loadingCircle = true

      const initialDate = this.getStartDate(this.dateList)
      const finalDate = this.getFinalDate(this.dateList)

      // cSpell: disable
      const body = {
        idrelatorioconfiguracao: 198,
        idrelatorioconsulta: 98,
        idrelatorioconfiguracaoleiaute: 198,
        idrelatoriousuarioleiaute: 1454,
        ididioma: 1,
        listaempresas: Array(String(this.companyIdSelected)),
        filtros: `ExibeParcelaRecebimento=False;
        DesconsiderarComissoesComParcelasZeradas=True;
        Funcionario=null;
        Comissoes=1,2,3,6;
        TipoFuncionario=2,8,9;
        DataInicial=${initialDate};
        DataFinal=${finalDate};
        parcela=0;
        somenteparcelarecebidaconcessionaria=False`,
      }

      const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmNjRmYmM4MS0zOTY4LTQ3YjYtODkyZC04NWU0MDQ3MTVkNzAiLCJvcmciOiJvcmcwMDAxMjEifQ.VEDLqbRglJY-MWcwlOVCjO2BHtwxG9rYzUwz1z4EB4M'

      await axios
        .post('https://microworkcloud.com.br/api/integracao/terceiro', body, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(async response => {
          this.commissionList = this.addUserCodeInCommissionList(response.data)
          this.loadingCircle = false

          this.showMessage({
            icon: 'success',
            title: 'Sucesso',
            text: 'Os dados foram obtidos, clique para exportar',
            timer: 4000,
          })

          this.disableDownloadButton = false
        })
        .catch(error => {
          this.showMessage({
            title: 'Erro ao enviar dados',
            text: error,
          })

          this.loadingCircle = false
        })
    },

    addUserCodeInCommissionList(data) {
      const newData = data.map(dataEmployee => {
        const employeeWithCode = this.employeeList.find(employee => employee.name == dataEmployee.funcionario)

        return { ...dataEmployee, cod_self: employeeWithCode?.cod_self }
      })

      return newData
    },

    async exportCsv() {
      if (!this.commissionList.length) {
        this.showMessage({
          title: 'Vazio',
          text: 'Nenhuma informação foi encontrada para o filtro solicitado!',
        })

        this.clearData()

        return
      }

      await this.getCsv()
    },

    async getCsv() {
      this.loaderExport = true

      const companySelected = this.companyList.find(object => object.id === this.companyIdSelected)
      const eventNumber = 3
      let csv = ''

      this.commissionList.forEach(item => {
        csv += `${Number(item.cod_self)};${eventNumber};${this.removeSpecialCharactersFromCommission(
          item.valorcomissao,
        )}\n`
      })

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `Comissões - ${companySelected.name}.csv`
      link.click()

      this.loaderExport = false
      this.clearData()
    },

    /**
     * @param {array.<string>} dates
     * @returns {string} Start date
     */
    getStartDate(dates) {
      if (Array.isArray(dates)) {
        dates.sort((firstDate, secondDate) => new Date(firstDate) - new Date(secondDate))
        const startDate = dates[0]

        return startDate
      }

      return dates
    },

    /**
     * @param {array.<string>} dates
     * @returns {string} Final date
     */
    getFinalDate(dates) {
      if (Array.isArray(dates)) {
        dates.sort((startDate, finalDate) => new Date(startDate) - new Date(finalDate))
        const finalDate = dates[dates.length - 1]

        return finalDate
      }

      return dates
    },

    /**
     * @param {string} value
     * @returns {number}
     */
    removeSpecialCharactersFromCommission(value) {
      const removeCharactersRegex = /[^\d]/g
      const BrazilianStandardValueFormat = Number(value).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })

      return Number(BrazilianStandardValueFormat.replace(removeCharactersRegex, ''))
    },

    /**
     * Brazilian standard date format
     *
     * @param {string} date
     * @returns {string}
     */
    formatDate(date) {
      if (typeof date === 'string') {
        const [year, month, day] = date.split('-')

        return `${day}/${month}/${year}`
      }

      return ''
    },

    clearData() {
      this.dateList = []
      this.companyIdSelected = ''
      this.disableDownloadButton = true
    },
  },
}
</script>
